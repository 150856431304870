html, body, #root, #root>div, #root>div>div { height: 100% !important;}
body { margin: 0; }

.header-login { background-color: #f9f9f9; padding: 10px 0; margin-bottom: 20px; border-bottom: 1px solid #ddd;}

.header-button { margin-left:5px; font-size: 16px; padding: 4px 8px; background-color: #f8f8f8; color: #666;}
.header-button svg { min-width: 22px}

.header-account { margin-top: 5px; width: 100px; border: 1px solid red;}

.work-space { height: 100%; position: relative; /*border: 1px solid green*/}

.header-operation { position: absolute; top: 10px; right: 10px; z-index: 50}
.gps-map-container { }
.gps-map-logo {}
.gps-map { padding-left: 300px; padding-bottom: 300px; width: 100%; height: 100%; position: absolute; right: 0; z-index: 10; font-size: 16px;} /*border: 1px solid blue; height: calc(100% - 49px - 300px); width: calc(100% - 300px); */
.down-drawer { /*border: 1px solid yellow;*/ position: absolute; bottom: 0; right: 0;  height: 300px; width: calc(100% - 300px); padding: 5px; background-color: #fff; box-shadow: 0 -3px 6px rgba(0,0,0,0.15); z-index: 20;}
.left-drawer { /*border: 1px solid red;*/ position: absolute; height: 100%; width: 300px; padding:5px; background-color: #f5f5f5; box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); z-index: 30}
.drawer-box {  border: 1px solid #ccc; border-radius: 2px; padding: 5px; box-shadow: 0 2px 4px rgba(0,0,0,0.3); background-color: #fff; }

.title { border-bottom: 1px dashed rgba(0,0,0,.2)}
.title h2 { font-size: 14px; padding: 2px; margin: 0;}
.title h3 { font-size: 13px; padding: 2px; margin: 0;}

.filter-list-btn { margin-right: 5px; font-size: 16px; line-height: 17px; text-shadow: 2px 2px 15px #000; padding:6px 0 6px 1px}
.btn-filter { font-size: 16px;}

.device-list, .geozones-list { min-height: 100px; /*370px;*/ overflow: auto;}
.device-item, .geozone-item { padding: 5px; border-radius: 2px;}
.device-item input[type='checkbox'] { height: 17px;}
.device-item:hover, .geozone-item:hover { background-color: #ddd;}
.device-active, .geozone-active { background-color: #ccc;}
.device-icon, .geozone-icon { float: left; width: 26px; height: 26px; border-radius: 50%; color: #fff; text-align: center; padding: 2px 0; font-size: 16px;}
.device-name, .geozone-name { float: left; margin-left:5px; line-height: 24px; font-weight: bold;}
.device-select { float: right; }

.device-info { font-size: 12px; line-height: 16px;}

.track-list { font-size: 12px; overflow-y: scroll; overflow-x: hidden}
.track-list ul { margin: 0; padding: 0; list-style: none; cursor: default}
.track-list li { padding: 2px; }
.track-list li:hover { background-color: #ddd;}
.track-active { background-color: #ccc;}
.tracks-loading {text-align: center;}
.tracks-loading span { display: block; font-weight: 300; font-size: 26px;}

.track-info { font-size: 12px; overflow-y: scroll; overflow-x: hidden;}
.track-info .row { margin: 0;}
.track-info .row div { padding-left: 5px; padding-right: 5px;}
.track-info .row:nth-child(even) { background: #ccc; background-clip: padding-box;}
.track-list .alert, .track-info .alert { min-height: 72px;}

.logo1{ position: absolute; left: 310px; bottom: 310px; z-index:12; }
.logo1 img, .logo2 img{ max-height: 60px; display: inline-block; opacity: 0.8;}
.logo2{ position: absolute; left: 440px; bottom: 310px; z-index:13; }
.map-loading { position: absolute; left:345px; top: 10px; z-index:14; color:rgb(0,60,136); text-align: center; opacity: 0.5;}


.bg-blue { background-color: #004dcc;}
.bg-green { background-color: #05cc00;}
.bg-red { background-color: #cc0000;}
.bg-pink { background-color: #b600cc;}
.bg-grey { background-color: #9d9d9d;}

.c-blue { color: #004dcc;}
.c-green { color: #05cc00;}
.c-red { color: #cc0000;}
.c-pink { color: #b600cc;}
.c-grey { color: #9d9d9d;}

.mt1 { margin-top: 1px;}
.mt2 { margin-top: 2px;}
.mt5 { margin-top: 5px;}
.mt10 { margin-top: 10px;}

.ml5 { margin-left: 5px}

.plr5 { padding-left: 5px; padding-right: 5px;}

.fs10 { font-size: 10px;}
.fs12 { font-size: 12px;}
.fs14 { font-size: 14px;}

.clear { clear: both;}
.no-padding { padding:0 !important;}
.no-left-padding { padding-left: 0 !important;}
.no-right-padding { padding-right: 0 !important;}
.no-margin { margin: 0 !important;}
.bold { font-weight: bold;}
.text-over { overflow:hidden; white-space:nowrap; text-overflow: ellipsis;}
.h100 { height: 100%;}
.tl { text-align: left;}
.tr { text-align: right;}
.tc { text-align: center;}

.br { border: 1px solid red !important;}
.bb { border: 1px solid blue !important;}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.flex-center {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* dropdown menu */
.dropdown-menu_TEST {
    position: fixed;
}
.dropdown-menu > li > button {
    border: 0;
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu > li > button:focus, .dropdown-menu > li > button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #3276b1;
}

/* modal */
@media (min-width: 768px) {
    .modal-xl {
        width: 90%;
        max-width:1200px;
    }
}

.modal-header { background-color: #4c4f53; color:#fff;}
.modal .widget-body-toolbar { margin: 0;}
.modal-toolbar .form-group {margin: 0;}
.app-toolbar-button { font-size: 21px; padding: 0 12px}
.modal-body { padding: 0;}
.a_modal-body table { border-top:3px solid #9cb4c5;}
.a_modal-body table tr th{ color: #305d8c; background-color: #d6dde7; border: 3px solid #9cb4c5; border-bottom: 3px solid #9cb4c5;text-align: center; vertical-align: middle; white-space:nowrap;}
.a_modal-body table tr td{ white-space:nowrap;}
.a_modal-body table { table-layout: auto;}
.row_number { text-align: right;}

.datepicker11{z-index:1151 !important;}

.close:focus, .close:hover{ color:#fff;}
.modal-content-app { height: 550px;}
.modal-toolbar { }

.panel { margin-bottom: 0;}
.panel-track-details {overflow: scroll; border: 1px solid #ddd;}
.panel-track-details table { min-width: 800px;}
.panel-track-details table .engine-off > tr > td,
.panel-track-details table .engine-off > tr > th,
.panel-track-details table .engine-off > tr > td,
.panel-track-details table .engine-off > tr > th,
.panel-track-details table .engine-off > tr > td,
.panel-track-details table .engine-off > tr > th { padding: 2px 5px !important;}
.panel-track-details table .engine-off { background-color: rgba(182, 0, 204, 0.3);}
.panel-track-details table .engine-off:hover { background-color: rgba(182, 0, 204, 0.6); }
.panel-track-details table .stoped { background-color: rgba(204,0,0,0.3);}
.panel-track-details table .stoped:hover { background-color: rgba(204,0,0,0.6);}
.panel-track-details table .movement { background-color: rgba(5, 204, 0, 0.3);}
.panel-track-details table .movement:hover { background-color: rgba(5, 204, 0, 0.6);}
.mobileye-details-column-img { height: 20px; margin-right: 2px;}

.ver {  font-size: 14px; opacity: 0.6; text-shadow: #fff 0 0 3px; text-align: center;}

.driver-select { padding: 0 !important;}
.driver-select select { height: 32px; border: 1px solid #ccc; color: #555;}

.form-group { margin-bottom: 5px;}

.table-details-panel { overflow: auto; overflow-y: scroll;  font-size: 12px;}
.table-details-panel table {table-layout: auto;}

.display-none {display: none;}
.popover-content { min-width: 200px; font-size: 10px; padding: 3px}
.popover-content svg { font-size: 16px; margin: 0 auto; display: block; margin-top: 5px;}
.popover-title { padding: 4px 8px;}

.checkbox-fix { padding-left: 0;}
.checkbox-fix label { padding-left: 8px; padding-top: 1px}

.loading { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }
.loading-text { margin-left: 10px; font-size: 4em; color: #cef; display: block}


